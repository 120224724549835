export const i18n_pt_br = {
  Real_money: 'Moeda real',
  Play_money: 'Moeda de jogo',
  in_Real_Money: 'em Moeda Real',
  in_Play_Money: 'em Moeda de Jogo',
  Login_to_your_account: 'Fazer login',
  Proceed: 'Continuar',

  global: {
    locale: 'pt_BR',
    content_language: 'pt-br',
    general_description:
      'Encontre as previsões mais certeiras e atualizadas no Futuur.  Melhores previsões usando a intelligência coletiva.',
  },

  error_messages: {
    wager_balance_not_enough: 'Não há saldo suficiente em sua carteira.',
    wager_question_closed: 'A pergunta selecionada não está disponível.',
    wager_outcome_conflict:
      'Você já fez outra aposta nesta pergunta para um resultado diferente. Por favor, realize a venda da aposta anterior e tente novamente.',
    wager_amount_invalid:
      'O valor definido não é válido. Por favor, selecione um valor válido ou entre em contato com nossa equipe para obter ajuda.',
    wager_max_shares_per_wager: 'Desculpe, você atingiu o valor máximo de ações por aposta',
  },

  snackbar: {
    copylink: 'copiado para a área de transferência',
  },

  footer: {
    title: 'Aposte no Futuro',
    about: 'Sobre',
    signup: 'Registre-se agora',
    how_works: 'Sobre o Futuur',
    terms: 'Termos e Condições',
    privacy: 'Política de Privacidade',
    contest_rules: 'Regras das Competições',
    explore: 'Descubra',
    leaderboard: 'Melhores Jogadores',
    questions: 'Principais Mercados',
    politics: 'Política',
    sports: 'Esportes',
    finance: 'Finanças',
    entertainment: 'Entretenimento',
    science: 'Ciências',
    engage: 'Envolva-se',
    suggest: 'Sugira um Mercado',
    contact: 'Fale Conosco',
    community: 'Comunidade',
    language_title: 'Idioma',
    language: 'English Site',
    report: 'Reportar um erro',
    copyright: 'Futuur© todos os direitos reservados',
    hiring: 'Estamos contratando',
    career: 'Carreira',
    api_docs: 'API pública',
    help_center: 'Central de Ajuda',
    disclaimer:
      'Futuur é um mercado de previsões que oferece previsões mais precisas em uma variedade de tópicos, impulsionado pela sabedoria da multidão.',
    psp_disclamer:
      'A Futuur pertence e é operada pela Futuur Anjouan Ltd, uma empresa anjouana com endereço registado em Hamchako, Mutsamudu, Ilha Autónoma de Anjouan, União das Comores. A Futuur é licenciada e regulada pelo Governo da Ilha Autónoma de Anjouan, União das Comores, e opera sob a licença n.º ALSI-202410061-FI2. Futuur passou todas as conformidades regulamentares e está legalmente autorizada a conduzir operações de jogos online para todos e quaisquer jogos de azar e apostas.<br/><br/>Os pagamentos podem ser processados através da Futuur Ltd., uma empresa subsidiária com sede em Voukourestiou 25, Neptune House, 1st Floor, Office 11, Zakaki, 3045, Limassol, Chipre.',
  },

  go_to_web_version: {
    title: 'Leve suas previsões ao próximo nível!',
    p: 'Utilize criptomoedas para realizar apostas em mercados com dinheiro real.',
    cta: 'Ir para versão web',
  },
  cancel_withdraw: {
    exit: 'Retorne para minha carteira',
    action: 'QUERO CANCELAR O PEDIDO',
    title: 'Você tem certeza de que quer cancelar este pedido?',
    description:
      'Sua transação permanecerá em sua conta, e não será transferida. Você pode fazer outro pedido a qualquer momento',
  },
  login_reset: {
    title: 'Problemas com login?',
    insert_your_email: 'Insira seu e-mail e enviaremos um link de recuperação',
    recover: 'Recuperar',
    invalid_email: 'E-mail inválido',
    success: 'Senha atualizada',
    reset_password: 'Redefinir Senha',
    success_text: 'Agora realize o login em sua conta e boas previsões!',
    invalid_token_param: 'A URL acessada é inválida',
    error: 'Token é inválido. Por favor, comece a recuperação de senha novamente.',
  },

  signup: {
    welcome: 'Bem vindo ao Futuur, uma comunidade onde prevemos o futuro',
    signin: 'Fazer login',
    signup: 'Registrar',
    full_name: 'Nome completo',
    email: 'Email',
    password: 'Senha',
    choose_a: 'Escolha um ',
    error_email: 'endereço inválido',
    error_name: 'Você deve preencher seu nome completo',
    error_too_short: 'deve ser maior que 8 caracteres',
    create_account: 'Criar Conta',
    set_pp: 'Defina sua foto de perfil, ',
    continue: 'Continuar',
    username_placeholder: 'nomedeusuario',
    welcome_to_futuur: 'Boas vindas ao Futuur',
    username: 'Nome de usuário',
    pick_username: 'Selecionar',
    password_helper_1: 'Ao menos 8 caracteres com números e letras',
    password_helper_2: 'conter números e letras',
    password_helper_3: 'pelo menos um caractere especial',
    already_forecaster: 'Já é um usuário?',
    email_placeholder: 'exemplo@email.com',
    title: 'Futuur ~ Registre-se',
    setting_up_account: 'Preparando sua conta...',
    choose_username: 'Por favor, escolha um nome de usuário',
    you_got_mail: 'Você recebeu um e-mail, verifique sua caixa de entrada',
    username_warning:
      'Este será seu nome de usuário na comunidade do Futuur. Escolha com sabedoria — você não poderá mudá-lo depois!',
    choose_profile_picture: 'Por favor escolha uma imagem de perfil',
    choose_profile_picture_info: 'Escolha uma imagem de perfil. Você poderá mudá-la sempre quando quiser.',
    account_settings: 'configurações de conta',
    ready_to_predict: 'Estou pronto!',
    proceed_without_verifying: 'Continuar sem verificação',
    verify_later: 'Verificar depois',
    add_picture_later: 'Adicionar mais tarde?',
    skip: 'Ignorar.',
    proceed: 'Continuar',
    terms: 'Termos de uso',
    privacy_policy: 'Política de Privacidade',
    check_out_1: 'Verifique nossos ',
    check_out_2: ' e ',
    enter_with_email: 'Entrar com email',
    verification_info:
      'Para realizar apostas, você precisa verificar seu e-mail. Você pode continuar navegando sem a verificação, mas não será possível realizar apostas. Se você tiver qualquer questão, acesse nossa ',
    identity_verification_policy: 'política de verificação de identidade',
    email_verified: 'E-mail verificado',
    time_to_predict_the_future: 'Legal! Agora é hora de começar a prever o futuro!',
    login_to_your_account: 'Faça login em sua conta',
    start_forecasting: 'Começar a prever',
    account_username_already_taken: 'O nome de usuário da conta já foi usado',
    invalid_link: 'O link que você clicou não é valido.',
    go_to_futuur: 'Ir para o Futuur',
  },

  signin: {
    title: 'Futuur ~ Login',
  },

  maintenance: {
    title: 'Futuur está em manutenção',
    text: 'Esperamos estar de volta em algumas horas. Obrigado pela compreensão.',
  },
  tags: {
    meta_tags: {
      title: 'Mercados de previsão (prediction markets) sobre todo assunto',
      description:
        'Futuur é um Prediction Market, um mercado de previsões, onde você pode apostar em eventos futuros, e ajudar a gerar previsões mais certeiras para todos!',
    },
  },
  tag: {
    meta_tags: {
      title: 'Previsões sobre ${this.tag}. Aposte no Futuro.',
      description: 'Veja mais de ${this.marketCount} previsões sobre ${this.tag} na Futuur.',
    },
  },
  landing: {
    meta_tags: {
      title: '🔮 Futuur ~ Aposte no Futuro',
      description:
        'Explore mais de ${this.marketCount} previsões baseadas em crowdsourcing na principal plataforma de mercado de previsões e faça sua própria aposta no futuro usando moeda real ou de jogo.',
    },
    title_line1: 'Preveja o',
    title_line2: 'Futuro',
    hero_text:
      'Futuur é um jogo de previsões comunitário e uma plataforma para prever o futuro usando a inteligência coletiva.',
    start_forecasting: 'Comece a prever',
    view_predictions: 'Ver previsões',
    fb_enter: 'Entre com o Facebook',
    signup: 'Entre com um Email',
    watch: 'Assistir Video',
    how_it_works: 'Como funciona',
    learn_more: 'Saiba mais',
    categories_banners: 'Assuntos do momento 🔥',
  },

  feed_banner: {
    Trade_the_future: 'Negocie o futuro',
    Future_of_forecasting: 'O futuro da previsão',
    intro_text: 'Ganhe dinheiro com o seu conhecimento e ajude a criar melhores previsões para todos',
    start_forecasting: 'Comece a prever',
    make_your_forecasts: 'Faça suas apostas',
  },
  redeem_code: {
    title: 'Resgatar um código',
    redeem: 'Resgatar',
    success_message: 'Código aplicado com sucesso!',
  },
  comments_feature: {
    replies: 'Respostas',
    reply: 'Responder',
    share: 'Compartilhar',
    report: 'Denunciar',
    comment: 'Comentar',
    show: 'Mostrar',
    hide: 'Ocultar',
    write_your_reply: 'Escreva sua resposta',
    report_comment: {
      modal_header_text: 'Reportar',
      input_label: 'Qual o motivo',
      modal_info_message:
        'Obrigado pela sua denúncia. Revisaremos nossas políticas da comunidade para tomar a melhor decisão e manter nossa comunidade forte.',
      reason_input_placeholder: 'Explique brevemente o motivo',
      success_title: 'Obrigado pela denúncia',
      success_body: 'Trabalharemos para avaliar esta questão o mais rápido possível.',
      action_button_text: 'Enviar',
    },
    share_comment: {
      success_title: 'Link copiado!',
      success_body: 'O link está pronto para compartilhar.',
    },
    post_comment: {
      success_title: 'Seu comentário foi publicado com sucesso',
    },
    reply_to_comment: {
      success_title: 'Sua resposta foi publicada com sucesso',
    },
  },
  active_markets: {
    title: 'Mercados',
    results: 'resultados',
  },
  how_it_works: {
    intro: {
      title: 'Sobre o Futuur',
      p1: 'Futuur é um mercado de previsões: uma plataforma fomentada pela comunidade para gerar previsões mais precisas sobre eventos futuros.',
      p2: 'No Futuur, você pode apostar no futuro de praticamente qualquer coisa',
      p3: 'Faça apostas para aumentar sua reputação utilizando nossa Moeda de Jogo, ou apimente as coisas realizando apostas com as Criptomoedas mais populares.',
      cta: 'Acesse já',
    },
    header: {
      title1: 'Comece a fazer previsões',
      title2: 'em apenas alguns passos',
    },
    step1: {
      title1: 'Inscreva-se',
      title2: ' com email ou Google',
      paragraph:
        'O registro é rápido e gratuito. E você receberá 10.000ø em moeda de jogo para iniciar sua carreira como previsor!',
    },
    step2: {
      title1: 'Deposite',
      title2: ' fundos para apostar com dinheiro real ou aposte para se divertir com Ooms',
      paragraph:
        'Deposite com a moeda de sua escolha (BRL, EUR, USD e outras) ou com criptomoedas. E saque quando quiser.',
    },
    step3: {
      title1: 'Escolha entre',
      title2: ' centenas de mercados para prever',
      paragraph:
        'Futuur oferece o maior número de mercados com dinheiro real do mundo, cobrindo praticamente todos os assuntos imagináveis!',
    },
    step4: {
      title1: 'Comece a apostar',
      paragraph:
        ' As probabilidades de mercado refletem a previsão da multidão sobre a probabilidade de um resultado e podem ser uma oportunidade de lucro se você detectar uma avaliação incorreta.',
    },
    join_tips: {
      header1: 'Junte-se a uma comunidade global ',
      header2: 'de previsores',
      step1: {
        title1: 'Siga',
        title2: ' o dinheiro inteligente',
        paragraph: 'Siga os previsores mais precisos em nosso ranking.',
      },
      step2: {
        title1: 'Faça sua voz',
        title2: ' ser ouvida',
        paragraph: 'Aproveite os comentários para obter insights e contribuir para a discussão.',
      },
      step3: {
        title1: 'Sugira',
        title2: ' mercados',
        paragraph: 'Faça sua sugestão e obtenha uma visão mais clara do futuro aproveitando a inteligência coletiva.',
      },
    },
    use_strategy_title: 'Use estratégia para apostar e ganhar!',
    use_strategy_paragraph:
      'Os preços no Futuur são determinados pela atividade do mercado. Você pode comprar e vender posições a qualquer momento conforme os preços flutuam, garantindo ganhos sem esperar pelo resultado do evento.',
  },

  infinite_grid: {
    load_more_markets: 'Carregar mais Mercados',
    load_more: 'Carregar mais',
    retry: 'Tentar novamente',
  },

  login: {
    login_to_futuur: 'Acesse sua conta',
    presentation:
      ' é um jogo de previsões comunitário e uma plataforma para prever o futuro usando a inteligência coletiva.',
    login: 'Login',
    signup: 'Registre-se',
    or_separator: 'ou',
    email_label: 'Entre com seu email',
    facebook_sign_in: 'Entrar com Facebook',
    google_sign_in: 'Entrar com Google',
    facebook_sign_up: 'Registrar com Facebook',
    google_sign_up: 'Registrar com Google',
    email_invalid: 'Email Inválido',
    password_label: 'Digite sua senha',
    password_invalid: 'Deve ter mais de 8 caracteres com letras e números',
    login_button: 'Login',
    signup_with_email: 'Registrar com email',
    forgot_password: 'Esqueceu sua senha?',
    terms_text1: 'Consulte nossos ',
    terms_text2: ' e ',
    terms: 'Termos de Uso',
    dont_have_account: 'Não possui uma conta?',
    register: 'Registrar',
    create_your_account: 'Criar sua conta',
    already_forecaster: 'Já é um usuário?',
    privacy: 'Políticas de Privacidade',
    google_error: 'Ocorreu um erro ao realizar a autenticação com o Google',
    google_error_server: 'Ocorreu um erro ao realizar a autenticação do seu usuário do Google junto ao Futuur',
    wrong_password: `E-mail ou senha não conferem`,
    invalid_2fa_token: `Token de autenticação inválido`,
    server_failure: 'Falha ao se comunicar com o servidor. Por favor, entre em contato caso o erro persista.',
    two_factor_auth: {
      title: 'Código de autenticação',
      paragraph: 'Você pode encontrar seu código no aplicativo autenticador',
      have_problem: 'Está tendo problemas? Entre em contato com o nosso ',
      support: 'Suporte',
    },
    user_name_changed_success: 'Nome de usuário alterado com sucesso',
  },
  two_factor_auth_validation: {
    complete: {
      title: 'Dois fatores adicionados',
      paragraph: 'A autenticação de dois fatores protege sua conta exigindo um código de login quando você faz login',
    },
    back: 'Back',
    paragraph: 'Para ativar a autenticação de dois fatores, você precisa:',
    step1: 'Para ativar, escaneie o código QR usando aplicativos de autenticação, como:',
    step2: 'Depois de digitalizar o QR, digite o token gerado',
    enable: 'Habilitar 2FA',
    cant_scan_code: 'Não consegue digitalizar? Em vez disso, digite o código abaixo',
  },
  page_title: {
    markets: 'Mercados',
    explore: 'Descobrir',
    account: 'Minha conta',
    activity: 'Atividade',
    leaderboard: 'Ranking',
    followers: 'Seguidores',
    following: 'Seguindo',
    public_profile: 'Perfil',
    prediction_list: 'Previsões',

    question_suggestions: 'Sugestão de Previsões',
    question_details: 'Detalhes da Previsão',
    category: 'Categoria',
  },
  category_list: {
    custom_closes_in: 'Fecha em',
  },
  navbar: {
    markets: 'Mercados',
    portfolio: 'Portfólio',
    settings: 'Opções',
    notification: 'Notificações',
    leaderboard: 'Ranking',
    wallet: 'Carteira',
    search: 'Busca',
    search_input: 'Buscar previsões',
    mobile_login: 'Entrar',
    desktop_login: 'Login',
    signup: 'Cadastre-se',
    fb_login: 'Entrar',
    enter: 'Entrar',
    add_funds: 'Depositar',
    deposit: 'Depósito',
  },
  bitcoin_tooltip: {
    real_money: {
      title: 'Aposte em dinheiro real!',
      text1: 'Eleve seu jogo com dinheiro real',
    },
    play_money: {
      title: 'Mercados em Dinheiro de Verdade',
      text1: 'Não há apostas em dinheiro de verdade em sua jurisdição.',
      text2:
        'Contudo, você ainda pode visualizar os mercados e suas probabilidades (que são diferentes das de Ooms) clicando aqui.',
    },
    add_funds: 'Adicionar Saldo',
    deposit: 'Faça um depósito agora',
    dismiss: 'Fechar',
  },
  bitcoin_banner: {
    call_to_action: 'Leve suas previsões para o próximo nível!',
    deposit: 'Deposite Criptomoedas',
    add_funds: 'Adicionar saldo',
    buy_bitcoin: 'Comprar Criptomoedas',
  },
  navbar_menu: {
    settings: 'Configurações da conta',
    my_profile: 'Meu perfil',
  },
  location_not_allowed: {
    title: 'Localidade restrita.',
    text_part1:
      'Sua jurisdição não permite apostas em dinheiro real no Futuur.</br></br> Não se preocupe! Você poder usar Ooms, nossa moeda fictícia, para fazer apostas e provar suas habilidades em prever o futuro.',
    text_part2: 'para mais informações.',
    portfolio_text_part1:
      'Portfólio em Criptomoedas não está disponível. Para ver seu portfólio de Ooms, clique no saldo de sua conta logo acima e selecione Ooms.',
    terms_conditions: 'Termos e condições',
    change_play_money: 'Mudança para dinheiro virtual',
  },

  not_enough_funds: {
    content:
      'Você não possui saldo suficiente para realizar a aposta nesta moeda. Por favor, adicione saldo para realizar suas previsões.',
    text: 'Lorem ipsum dolor sit amet consectetur adip sit amet consectetur adip sit amet consectetur adip',
    add_funds: 'Adicionar saldo',
    use_another_currency: 'Usar outra moeda? Mudar ',
    here: 'aqui',
  },

  portfolio_wallet_entry: {
    active_bets: 'Apostas ativas',
    value_active_bets: 'Valor das apostas ativas',
    value_invested: 'Valor investido',
    current_profit: 'Lucro atual',
    return: 'Retorno',
    current_value: 'Valor atual',
  },
  faqs: {
    title: 'Perguntas frequentes',
    link_to_external: 'Ir para a documentação do Futuur',
    what_is_futuur: {
      title: 'O que é Futuur?',
      description:
        'Futuur é um mercado de previsões: uma plataforma impulsionada pela inteligência coletiva para gerar previsões mais precisas sobre eventos futuros. Os participantes fazem previsões ao apostar nelas, usando dinheiro virtual ou criptomoeda. Nosso objetivo é aproveitar a sabedoria da multidão para fazer previsões melhores em um mundo imprevisível. Saiba mais sobre o Futuur em nossa <a class="link">página Sobre</a>.',
    },
    how_are_priced_determined: {
      title: 'Como os preços são determinados? O que é AMM?',
      description:
        "Futuur utiliza um Criador de Mercado Automatizado (AMM, na sigla em inglês), um método algorítmico que nos permite oferecer um preço de compra ou venda para qualquer resultado a qualquer momento. Isso difere do modelo tradicional de 'livro de ofertas' visto em bolsas de valores ou mercados financeiros, onde ambas as partes de uma negociação precisam estar presentes simultaneamente para que uma transação ocorra. O modelo AMM oferece a vantagem de liquidez constante e imediata em todos os nossos mercados, mantendo ainda os benefícios de uma precificação dinâmica baseada em troca.",
    },
    do_you_have_api: {
      title: 'Existe uma API pública?',
      description:
        'Sim! Confira nossa <a class="link" href="https://api.futuur.com/docs/">documentação da API</a>. Para gerar uma chave privada e começar a usar a API, vá até a página de Configurações. Se você tiver dúvidas, sugestões ou qualquer outro feedback sobre a API, entre em contato em <span class="link">support@futuur.com</span>.',
    },
    suggest_a_market: {
      title: 'Tenho uma ideia para um mercado. Onde posso sugerir?',
      description:
        'Estamos sempre animados para introduzir novos e intrigantes mercados no Futuur. Se você tem uma sugestão para um novo mercado, compartilhe conosco aqui: <a class="link" href="https://futuur.com/i/feedback/suggestion">sugerir um mercado</a>.',
    },
  },
  settings: {
    title: 'Configurações',
    feedback: 'Feedback',
    contact: 'Fale Conosco',
    suggest_question: 'Sugira um mercado',
    report_bug: 'Reporte um problema',
    report_bug_continuation: 'ou nos envie uma sugestão',
    other_feedback: 'Outro tipo de sugestão',
    rate_app_store: 'Avalie-nos na App Store',
    rate_play_store: 'Avalie-nos na Play Store',
    about: 'Sobre',
    how_works: 'Como Funciona o Futuur?',
    preferences: 'Preferências',
    language: 'Idioma',
    language_en: 'EN',
    language_pt: 'PT',
    account: 'Minha conta',
    logout: 'Sair',
    notifications: 'Notificações',
    notification_live_events: 'Eventos ao vivo',
    notification_question_resolution: 'Resolução de previsões',
    notification_question_resolution_small: 'das previsões realizadas por você',
    notification_new_followers: 'Novos seguidores',
    notification_comments: 'Comentários',
    notification_comments_small: 'em tópicos que você está seguindo',
    contracts: 'Contratos',
    personal_info: 'Informações pessoais',
    login_security: 'Login & Segurança',
    payments_payouts: 'Pagamentos e Saques',
    terms_of_use: 'Termos de Uso',
    legal: 'Legal',
    privacy_policy: 'Política de Privacidade',
    contest_rules: 'Regras das Competições',
    go_to_profile: 'Ir para o perfil',
    edit_profile: 'Editar meu perfil',
    update_password: 'Alterar senha',
    change_email: 'Alterar e-mail',
    update_email: 'Atualizar E-mail',
    you_have_an_inbox: 'Você tem uma nova mensagem!',
    your_current_email_is: 'Seu e-mail atual é',
    send_confirmation: 'Enviar link de confirmação',
    invalid_email: 'endereço de e-mail inválido',
    confirm_email_to_proceed: 'Confirme seu e-mail para continuar',
    use_an_email_address_with_access: 'Use um endereço de e-mail que você sempre terá acesso',
    payment_methods: 'Formas de pagamento',
    account_settings: 'Conta',
    identity_verification: 'Verificação de Identidade',
    faq: 'F.A.Q.',
    faq_continuation: 'Tire suas dúvidas',
    update_personal_info: 'Atualizar dados pessoais',
    public_api_section: {
      title: 'API pública',
      key: 'API',
      beta_desclaimer: 'A API pública do Futuur está em fase Beta.',
      link_for_documentation: 'Link para documentação',
      pending_info: 'We’re validating your request and soon will return via email.',
      request_access: 'Habilitar acesso por API',
      generate_keys: 'Gerar chaves',
      public: 'Pública',
      private: 'Privada',
      delete_keys: 'Remover Chaves',
      generate_new_keys: 'Gerar novas chaves',
      modal_keys_title: 'Par de chaves Pública/Privada',
      request_sent_title: 'Solicitação para acessar a API Pública realizada',
      request_sent_description:
        'Parabéns, agora você faz parte do programa de API beta e pode gerar uma chave de acesso. Observe que o programa API está em versão beta e atualmente fornece acesso apenas a mercados de dinheiro fictício.',
      for_security_reasons:
        'Por questões de segurança, você só poderá ver sua chave privada uma única vez. NÃO COMPARTILHE ESTA CHAVE com ninguém. Se você perder sua chave privada, será necessário gerar um novo par de chaves.',
      enable_to: 'Habilitado para',
      must_verify:
        'A API pública do Futuur ainda está em sua fase Beta e por isso está disponível apenas para moeda de jogo. Em breve a versão com moeda de verdade será liberada.',
      if_you_lose: 'Se você perder sua chave privada, será necessário gerar um novo par de chaves. ',
    },
    two_factor: {
      on: 'On',
      off: 'Desligado',
      pending: 'Pendente',
      info: 'A autenticação de dois fatores protege sua conta exigindo um código no login',
      enable: 'Ativar',
      disable: 'Desativar',
    },
    security_settings: 'Segurança',
    have_any_further_questions: 'Tem mais alguma dúvida?',
  },
  update_request: {
    need_update: 'Atualização necessária',
    update: 'ATUALIZAR',
    title: 'Atualize seu aplicativo para a nova versão',
    description:
      'Ops! Parece que você está usando uma versão antiga da Futuur. Para que continue a fazer previsões de forma segura, por favor, atualize-o.',
  },
  activity: {
    tabs: {
      user: 'Suas atividades',
      following: 'Quem você segue',
    },
    empty_list: 'Nenhuma notificação ainda',
    live_now: 'está ao vivo',
    you_got_it_right: 'Você acertou!',
    bet_on: 'Apostou em',
    resolved_on: 'Resolvido em',
    incorrect: 'Incorreto',
    correct_outcome_was: 'Resposta correta foi',
    withdraw_success: 'Retirada completa',
    withdraw_request: 'Solicitação de saque recebida',
    follow: 'Seguir',
    following: 'Seguindo',
    view_profile: 'Ver Perfil',
    canceled: 'foi cancelada',
    deposit_completed: 'Depósito Realizado',
    deposit_pending: 'Depósito em Processamento',
    welcome_to_futuur: 'Bem vindo ao Futuur',
    notification_amount_earned:
      'Obrigado por registrar como um previsor no Futuur! Para acessar todas as funcionalidades de nossa plataforma, por favor ative sua conta clicando no botão do e-mail que enviamos.',
    resend: 'Re-enviar',
    change_email: 'Alterar E-mail',
  },
  filters: {
    people_i_follow: 'Pessoas que eu sigo',
    hide_my_bets: 'Esconder minhas apostas',
    open_markets: 'Mercados abertos',
    resolved_market: 'Mercados resolvidos',
    market_i_follow: 'Apenas mercados que sigo',
    default_category: 'Todas categorias',
    categories: 'Categorias',
    status: 'Status',
    filter_by: 'Filtrar por',
    apply: 'Aplicar filtros',
  },
  markets: {
    all_questions: 'Todos os Mercados',
    no_markets: 'Não há Mercados',
    no_markets_clear_filters: 'Nenhum mercado encontrado, por favor altere os filtros aplicados.',
    title_category_prefix: 'Previsões e apostas sobre ',
    title_category_suffix: '',
    title_meta: 'Previsões e apostas sobre',
    make_your_forecasts: 'Faça suas previsões',
    all_markets: 'Todos os Mercados',
    live_markets: 'Ao Vivo',
    resolved_and_sold: 'Resolvidos e Vendidos',
    resolved_only: 'Resolvidos',
    sort_by: 'Ordernar Por',
    most_recent: 'Mais Recente',
    bet_end_date: 'Data limite para apostas',
    number_of_bets: 'Número de Apostas',
    relevance: 'Relevância',
    volume: 'Volume',
    default: 'Padrão',
    link_copied: 'Cópia do link do mercado !',
  },
  search: {
    input: 'Buscar ...',
    tabs: {
      all: 'Todos',
      categories: 'Categorias',
      users: 'Pessoas',
      questions: 'Mercados',
      tags: 'Tags',
    },
    result: {
      question: {
        title: 'Mercados',
        cta: 'ver todos os Mercados',
        total: 'mercados',
      },
      category: {
        title: 'Categorias',
        cta: 'ver todas as categorias',
        total: 'categorias',
      },
      user: {
        title: 'Pessoas',
        cta: 'ver todas as pessoas',
        total: 'pessoas',
      },
      tag: {
        title: 'Tags',
        cta: 'ver todas as tags',
        total: 'tags',
      },
    },
    empty: {
      title: 'Oops, não prevemos essa busca!',
      description: 'Nos conte o que você você gostaria de ver.',
      suggest_a_market: 'Sugerir um mercado',
    },
    min_required_chars_message: 'Digite pelo menos 3 caracteres para realizar uma pesquisa!',
    no_forecasters_found: "Nenhum especialista encontrado que corresponda à sua pesquisa",
    no_markets_found: "Nenhum mercado encontrado que corresponda à sua pesquisa",
    no_categories_found: "Nenhuma categoria encontrada que corresponda à sua pesquisa",
    no_tags_found: "Nenhuma tag encontrada que corresponda à sua pesquisa"
  },
  question_card: {
    date: {
      live: 'ao vivo',
      waiting_result: 'Aguardando resolução',
      resolved: 'encerrado',
      market_resolved: 'Encerrado',
      cancelled: 'cancelado',
      bet_end_date_in_minutes: 'Em ${this.minutes} minutos',
      bet_end_date_in_hours: 'Em ${this.hours} horas',
      bet_end_date_in_minutes_and_seconds: 'Em ${this.minutes} : ${this.seconds}',
    },
    stats_in_play_money: 'Dados de Moeda de Jogo',
    stats_in_real_money: 'Dados de Dinheiro Real',
    related_market: 'Mercados relacionados',
    bet_on: 'Apostou em',
    live_in: 'Ao vivo em',
    waiting_result: 'Aguardando resolução',
    finished: 'Encerrado',
    live_now: 'Ao Vivo',
    real_brier_score: 'Brier Score em moeda de jogo',
    play_brier_score: 'Brier Score em Moeda real',
    brier_score_tooltip:
      'O Brier Score é uma função de pontuação ou uma regra de pontuação que mede a precisão das previsões probabilísticas.',
    unavailable_currency:
      'Previsões indisponíveis em dinheiro real. Se você quiser realizar apostas em Ooms, clique em seu saldo para mudar.',

    tooltip_volume_PM:
      'Volume é o valor negociado em um mercado, mais sua liquidez inicial. Você está vendo volume em Ooms, nosso dinheiro fictício.  Para ver o volume em dinheiro real, selecione uma opção de dinheiro real clicando no saldo da sua conta acima, no cabeçalho.',
    tooltip_volume_RM:
      'Volume é o valor negociado em um mercado, mais sua liquidez inicial. É mostrado em equivalente em USD.',
    forecaster: 'Forecasters',
    volume_explained: 'Valor negociado em um mercado, mais sua liquidez inicial.',
    tooltip_playmoney:
      'Você está vendo mercados em moeda de jogo. Para ver mercados em moeda de verdade, clique no saldo da sua carteira no canto superior direito e selecione “mercados de moeda de verdade.',
    tooltip_realmoney:
      'Você está vendo mercados em moeda de verdade. Para ver mercados em moeda de jogo, clique no saldo da sua carteira no canto superior direito e selecione “mercados de moeda de jogo.',
    bets_on_the_market: 'Suas apostas neste mercado',
    start_date_hour_format: 'em ${this.hours} hora${this.prularHours} e ${this.minutes} minuto${this.prularMinutes}',
    start_date_hour_format_minutesOnly: 'em ${this.minutes} minuto${this.prular}',
    sufix_start_date_date: 'em ',
    view_bets: 'Ver Apostas',
    positions_in: 'posições vendidas ',
    currencies: 'moedas',
    no_funds_yet: 'Ainda não há fundos',
    bet_history: 'Histórico de Apostas',
    comments: 'Comentários',
    outcomes: 'Resultados',
    ends_in: 'Fecha',
    closed: 'Fechado',
  },

  question_summary: {
    market_rules: 'Regras do Mercado',
    about: 'Sobre esta pergunta',
    end_date: 'Data limite para apostas',
    event_start_date: 'Data de início do evento',
    at: 'às',
    activity: 'Atividade',
    activities: 'Atividades',
    transactions: 'Transações em',
    forecasters: ' usuários',
    in_play_money: 'em Moeda de Jogo',
    in_real_money: 'em Dinheiro Real',
    transaction_count: {
      transactions: 'Transações em ${this.transactions}',
      forecasters: ' de ${this.forecasters} previsores',
      transaction: '1 transação',
      forecaster: ' de 1 previsor',
    },
    see_more: 'Ver mais',
    see_less: 'Ver menos',
  },

  place_bet: {
    your_bet: 'Sua Aposta',
    price_per_share: 'Preço por ação',
    average_price: 'Média. Preço',
    if_you_win: 'Se você ganhar',
    amount: 'Valor',
    select_bet: 'Selecione uma aposta',
    bet: 'Apostar',
    place_bet: 'Fazer Aposta',
    share: 'Share',
    invalid_number: 'Valor inválido',
    insufficient_funds: 'Não há saldo suficiente',
    from_forbidden_country: 'Você só pode apostar usando dinheiro fictício (Ooms),',
    max_one_price_per_share:
      'Os ganhos potenciais são menores do que o valor de sua aposta. Ajuste sua aposta ou selecione um resultado diferente',
    amount_beyond_max: 'A aposta máxima deve ser de ',
    amount_below_min: 'A aposta mínima deve ser de ',
    outcome_not_selected: 'Selecione uma aposta',
    add_funds: 'Adicionar Saldo',
    outcome: 'Resultado',
    loading: 'Carregando...',
    reset: 'Limpar',
    fix: 'Corrigir',
    confirm_new_prices: 'Confirmar novos preços',
    prices_have_changed: 'Os preços mudaram',
    wager_balance_not_enough: 'Saldo insuficiente',
    wager_amount_invalid: 'Oops! Preços desatualizados. Por favor, atualize a página',
    wager_max_shares_per_wager: 'Desculpe, você atingiu o valor máximo de ações por aposta',
    add_real_money: 'Adicionar dinheiro real',
    btc_equivalent_popup:
      'Está a apostar usando ${this.coin}. Os montantes equivalentes a USD são mostrados para sua conveniência. Note que o montante "se ganhar" é garantido na moeda utilizada para apostar, e pode ter um valor equivalente em USD diferente devido a alterações na taxa de câmbio.',
    login_required: 'Por favor inicie sessão ou registe-se para fazer uma aposta',
    you_placed_a_bet: 'Você tem ${this.count} apostas abertas neste mercado. Gostaria de fazer mais uma?',
    bet_again: 'Aposte novamente',
    resolved_market: 'Este mercado está resolvido. é impossível fazer apostas neste mercado por enquanto.',
    resolution: 'Resolução',
    resolved: 'resolvido',
    canceled: 'Mercado Cancelado',
    reason: 'Motivo',
    cancelation_date: 'Cancelado em',
    this_market_is: 'Este mercado está ',
    you_will_recieve_notification:
      'Se você tem apostas ativas neste mercado, você receberá uma notificação assim que ele for resolvido.',
    errors: {
      amount_invalid: 'O valor fornecido não é válido. Por favor, verifique e tente novamente.',
      balance_not_enough:
        'O saldo da sua conta não é suficiente para concluir esta transação. Por favor, adicione fundos ou ajuste o valor da transação.',
      user_email_not_confirmed:
        'Seu endereço de e-mail ainda não foi confirmado. Por favor, verifique seu e-mail e siga as instruções para confirmar seu endereço de e-mail.',
      question_closed: 'O mercado ao qual você está tentando acessar está fechado. Por favor, tente outro mercado.',
      max_shares_per_wager:
        'Você excedeu o número máximo de ações permitidas para esta aposta. Por favor, ajuste a quantidade de ações e tente novamente.',
      wager_action_duplicated:
        'Sua aposta acabou de ser processada. Por favor, aguarde 5 segundos antes de fazer outra aposta.',
      balance_not_enough_with_opened_withdrawals:
        'O saldo da sua conta não é suficiente para concluir esta transação porque há uma solicitação de saque pendente. Por favor, cancele ou conclua a solicitação de saque antes de tentar novamente.',
      bets_blocked:
        'Você está atualmente bloqueado para fazer novas apostas. Por favor, conclua a verificação de identidade em suas configurações de conta.',
      invalid_hmac_key: 'A chave de segurança fornecida é inválida. Por favor, verifique e tente novamente.',
      real_money_bet_not_allowed:
        'Apostas com dinheiro real não são permitidas. Por favor, escolha outro tipo de aposta ou entre em contato com o suporte ao cliente para obter assistência.',
    },
    bet_made: 'Você acabou de fazer uma aposta de',
    bet_sell: 'Você acabou de vender sua aposta por',
    bought: 'Comprado !',
    sold: 'Aposta vendida!',
    view_market_details: 'Ver detalhes',
    pause_title: 'Mercado sob avaliação',
    pause_description:
      'Este mercado está sendo <b>avaliado por nosso time.</b></br> Todos os usuários com apostas ativas serão notificados em caso de encerramento.',
    yes: 'Sim',
    no: 'Não',
    sell_now: 'Vender agora',
    sell_for: 'Vender por',
    beyond_max_shares: 'Ações insuficientes',
    below_min_shares: 'abaixo do mínimo',
  },

  place_bet_success: {
    success: 'Sucesso!',
    bet_on: 'Apostou em',
    market: 'Mercado',
    your_bet: 'Sua Aposta',
    average_price: 'Preço Médio',
    if_you_win: 'Se você vencer',
    confirm_new_prices: 'Confirmar mudança de preço',
    prices_have_changed: 'Preços mudaram, por favor confirme',
    share_your_bet: 'Compartilhe sua aposta',
  },

  bet_status: {
    bet_info: 'Mais Informações',
    sell_now: 'Vender',
    you_sold: 'Você vendeu sua aposta',
    buy_more: 'Comprar mais',
    disabled: 'foi desativado',
    share: 'ação',
    shares: 'ações',
    transactions: 'Transações',
    transaction: 'Transação',
    open: {
      bet: 'Sua aposta',
      win: 'Se você ganhar',
      sell: 'Se vender agora',
    },
    closed: {
      bet: 'Você apostou',
      won: 'Ganhou!',
      lost: 'Perdeu',
    },
    sell: 'Vender',
    go_to_market: 'Ir ao mercado',
    of: 'em',
    at: 'por',
  },
  bet: {
    active_purchases_amount: 'Sua aposta',
    avg_price: 'Preço médio',
    amount_on_win: 'Se você ganhar',
    sell_for: 'Vender por',
    pending_result: 'Pendente',
    pending_info: 'O tempo de aposta finalizou e os resultados serão apurados.',
    bet: 'Aposta',
    sold: 'Vendeu',
    last_sell_amount: 'Vendido por',
    profit: 'Lucro Agregado',
    won_amount: 'Ganhou',
    you_lost: 'Perdeu',
    question: 'Mercado',
    follow: 'Você agora está seguindo o ',
    unfollow: 'Deixou de seguir ',
    reviewing: 'Avaliando',
    paused_info:
      'Estamos analisando este mercado. Usuários com apostas ativas serão notificados em caso de encerramento.',
    last_update: 'Última transação',
    bet_end_date: 'Data de término da aposta',
    active: 'Ativa',
  },
  sell_bet: {
    generic_error: 'Erro ao carregar aposta. Por favor, tente novamente.',
    not_auth: 'Usuário não autenticado. Por favor, realize seu login.',
    bet_sold: 'Você vendeu sua posição por ${this.amount} ${this.currency}',
  },
  bet_sell_panel: {
    confirm: 'Confirmar',
    selling: 'Vendendo',
    selling_for: 'Vendendo por',
    loss: 'Perda',
    profit: 'Lucro',
    confirm_new_prices: 'Confirmar novos preços',
    prices_changed: 'Os preços mudaram, por favor confirme',
  },
  transaction_card: {
    won: 'Ganhou',
    sold: 'Vendeu',
    lost: 'Perdeu',
    open: 'Apostou',
    canceled: 'Cancelado',
    reversed: 'Revertido',
    sold_for: 'Vendido por',
    partial_sell: 'Venda parcial',
    bet_amount: 'Valor da aposta',
    bet_on: 'Apostou em',
    date_time: 'Data e Hora',
    user: 'Usuário',
    currency: 'Moeda',
    action_outcome: 'Ação',
    amount: 'Quantia',
    anonymous: 'Anônimo',
    withdrawal_request: 'Saque',
    credit: 'Credit',
    deposit: 'Ação',
    wager_cancellation: 'Mercado Cancelado',
    wager_reversion: 'Revisão de Resolução',
    initial_balance: 'Saldo Inicial',
    other: 'Outro',
    completed: 'Concluído',
    processing: 'Processando...',
    pending_confirmation: 'Confirmação de e-mail pendente',
    resend_email: 'Reenviar e-mail',
    or: 'Ou',
    cancelWithdraw: 'Cancelar este saque',
  },
  bet_card: {
    bet: 'Apostou',
    on: 'em',
    sold: 'Vendeu a aposta',
    position: '',
    return: 'retorno',
    profit: 'lucro',
    loss: 'perda',
    bought: 'comprou',
    sold_bet: 'vendeu',
    won: 'ganhou',
    lost: 'perdeu',
  },
  question_details: {
    latest_bets: 'Últimas Previsões',
    price_history: 'Histórico de Preço',
    price_chart_times: ['1D', '1S', '1M', '3M', '1A'],
    filter_following: 'Apenas quem sigo',
    user_and_outcome: 'Usuário e resultado',
    amount_bet: 'Ações',
    price: 'Preço',
    predictions_cta: 'Ver todas as previsões',
    meta_tags: {
      title_prefix: '',
      title_suffix: ' Previsões e Aposta',
      outcome_chance: ' &ldquo;${this.outcome}&rdquo; tem ${this.chance}% de chance de ocorrer',
      description:
        'Veja as ultimas previsões sobre  ${this.subcategory}, geradas pela inteligencia coletiva.  E faça sua aposta no futuro! ',
    },
    sidebar_title: 'Preveja',
    sidebar_subtitle: 'o Futuro',
    sidebar_about:
      'Futuur é um mercado de previsões sobre o futuro. Esportes, política, entretenimento, finanças, ' +
      'de tudo um pouco! Mostre seu conhecimento e torne-se um mestre das previsões no mundo!',
    sidebar_fb: 'continue com o facebook',
    sidebar_video: 'assistir o video',
    sidebar_hiw: 'como funciona',
  },
  question_bet: {
    place_bet: 'Comprar',
    shares: 'Ações',
    price: 'Preço',
    fb_enter: 'continue com facebook',
    email_enter: 'entre com um email',
    average_price: 'Preço médio por ação',
    if_you_win: 'Se você ganhar',
    your_bet: 'Sua aposta',
    not_enough_funds: 'Saldo insuficiente',
    add_funds: 'Adicionar Saldo',
    nowWorth: 'Agora vale a pena',
    returnProfit: 'Lucro / Perda',
    confirm_prices: 'Confirmar novos preços',
    prices_have_changed: 'Os preços mudaram, por favor confirme',
  },
  public_make_bet: {
    text1: 'Por favor, registre-se para realizar apostas',
    fb_enter: 'continue com o Facebook',
    email_enter: 'Registre-se por email',
    login_enter: 'Já é um jogador? Faça seu ',
    login_enter_cta: 'login',
  },
  public_follow: {
    text1: 'Para seguir',
    text2: '— ou qualquer outro usuário do Futuur —',
    text3: 'você precisa estar registrado como um jogador.',
    fb_enter: 'continue com o facebook',
    email_enter: 'Registre-se por email',
    login_enter: 'Já é um jogador? Faça seu ',
    login_enter_cta: 'login',
  },
  question_item: {
    wager: {
      your_bet: 'Sua aposta',
      if_sell: 'Se vender agora',
      sell: 'Vender',
      if_win: 'Se sua previsão acontecer',
      math_details: 'Cálculos detalhados',
      correct_prediction: 'Você acertou!',
      wrong_prediction: 'Você errou!',
    },
  },
  // TODO: Translate this block;
  prediction_card: {
    cta: 'compartilhar previsão',
    paid: 'Preço pago',
    total: 'Total apostado',
    earnings: 'Ganhos',
    losses: 'Perda',
    question_link: 'ver detalhes da pergunta',
    resolved_date: 'resolvido em ',
    cancelled_date: 'cancelado em ',
    correctly_predicted: 'previu corretamente',
    incorrectly_predicted: 'previu incorretamente',
  },
  math_details: {
    bet: {
      title: 'Sua previsão',
      card: 'Você gastou com',
      description:
        'Você comprou um total de <em>${this.shares}</em> ações de <em>${this.outcome}</em>, gastando <em>${this.value}ø</em>.',
    },
    sell: {
      title: 'Se vender agora',
      card: 'Você receberá',
      current_price: 'Preço atual',
      tax: 'Taxa*',
      receive: 'Você receberá',
      profit: 'Lucro',
      description:
        '<em>Taxa</em>. Uma taxa é aplicada ao valor final de sua compra. A taxa varia de acordo com o impacto de sua participação na aposta.',
    },
    win: {
      title: 'Se você acertar',
      title_won: 'Você acertou',
      title_lost: 'Se você tivesse acertado',
      card: 'Você receberá',
      card_won: 'Você recebeu',
      card_lost: 'Você receberia',
      receive: 'Você receberá',
      receive_won: 'Você recebeu',
      receive_lost: 'Você receberia',
      spent: 'Ooms gastos',
      profit: 'Lucro',
      description1:
        '<em>Suas Ações</em>. Em caso de sucesso de sua previsão, as ${this.shares} ações que você comprou são convertidas em ${this.amount_on_win}ø. ' +
        'A quantidade de dinheiro recebido é sempre igual à quantidade de ações compradas, arredondadas para baixo.',
      description2:
        '<em>Ooms gastos</em>. Essa é a quantidade de Ooms gastos originalmente na sua compra. No caso, ajuda a determinar sua lucratividade.',
    },
    button: 'Ok, Entendi!',
  },
  prediction_list: {
    prediction: 'previsões',
    share: 'ações',
    price: 'preço',
    badge: 'venda',
    filter_all: 'todos os jogadores',
    filter_following: 'apenas quem sigo',
  },
  portfolio: {
    header: 'Portfólio',
    add_funds: 'Adicionar Saldo',
    active_bets_in: 'Apostas ativas em ',
    past_bets_in: 'Apostas passadas em ',
    view_wallet: 'Ir para sua carteira',
    value_of_active_bets: 'Valor de apostas ativas',
    add_funds_real_money: 'Coloque saldo para realizar apostas em dinheiro real',
    add_funds_or_switch: 'ou mude para Ooms e veja suas apostas em dinheiro de jogo',
    no_bets_in_real_money: 'Nenhuma aposta até o momento',
    no_bets_paragraph: 'Verifique nossos mercados e encontre o mais lucrativo para você',
    view_markets: 'Visualizar mercados',
    real_money: 'dinheiro real',
    play_money: 'moeda de jogo',
    select_currency: 'Selecionar moeda',
    tabs: {
      active: 'Abertas',
      closed: 'Fechadas',
    },
    summary: {
      value: 'Valor das apostas',
      size: 'Apostas ativas',
    },
    filter: {
      title: 'Suas apostas em',
      sort: 'Filtrar',
      active: 'Apostas ativas',
      past: 'Apostas passadas',
    },
    card: {
      active_bets_in: 'Apostas ativas em',
      active_bet_in: 'Aposta ativa em',
      past: 'Apostas passadas',
      last30: 'Últimos 30 dias',
      active: 'Apostas ativas',
      resolved: 'Encerradas ou vendidas',
      rate: 'Taxa de acerto',
      current_profit: 'Lucro Atual',
      return: 'Retorno',
      value_invested: 'Valor investido',
      value_active: 'Valor das apostas ativas',
      add_funds: 'Adicionar Saldo',
      wallet_BTC: 'Carteira de Bitcoin',
      wallet_OOM: 'Carteira de Ooms',
    },
    all_currencies: 'Todas as moedas',
    check_it_out: {
      title: 'Apresentamos a você nosso novo e aprimorado portfólio 🔮',
      paragraph:
        'Agora você tem <b class="font-bold">novos filtros</b> e uma <b class="font-bold">nova exibição de tabela.</b>',
      button: 'Dê uma olhada',
    },
    last_30_days: 'Últimos 30 dias',
    most_bet_category: 'Categoria mais apostada',
    you_have_no_bets: 'Você não tem apostas abertas',
    bet_on_hot_topic: 'Que tal apostar nos nossos Tópicos Quentes? 🔥',
    life_time: 'Lifetime',
    win_rate: 'Taxa de vitória',
    bet_placed: 'Apostas feitas',
    ranking: 'classificação',
    search_placeholder: 'Procurar no Portfólio',
  },
  portfolio_summary: {
    active_bets_singular: 'Aposta Ativa',
    active_bets_plural: 'Apostas Ativas',
    in: 'em',
    play_money: 'Moeda de Jogo',
    real_money: 'Moeda Real',
  },
  profile: {
    follow_action: 'Seguir',
    following_action: 'Seguindo',
    invite_friends: 'Convidar Amigos',
    net_worth: 'Balanço Total',
    win_rate: 'Taxa de Acerto',
    total_wagers: 'Previsões',
    bet: 'Aposta',
    bets: 'Apostas',
    followers: 'Seguidores',
    following: 'Seguindo',
    no_users: 'Nenhum usuário encontrado',
    number_of_bets: 'Apostas em ${this.currency}',
    bets_data_title: 'Dados de apostas em ${this.currency}',
    value_active_bets: 'Valor das Previsões em Aberto',
    account_settings: 'Minha Conta',
    sell_all: 'Vender Todas as Previsões em Aberto',
    no_bets: 'Você não tem previsões ativas. Suas próximas previsões estarão aqui.',
    sell_all_question: 'Vender todas as previsões?',
    restricted_panel_title: 'Histórico de Apostas para apostas em Dinheiro Real não está disponível',
    restricted_panel_text:
      'Para visualizar o Histórico de Apostas deste usuário em Dinheiro de Jogo, clique no saldo de sua conta selecione Ooms.',
    meta_tags: {
      public_title: ' na Futuur | veja as previsões',
      description_outcome:
        'Veja as últimas previsões de ${this.username} na Futuur.  ${this.username} apostou em &ldquo;${this.outcome}&rdquo; na pergunta &ldquo;${this.question}&rdquo;.  Veja outras previsões de ${this.username} e faça suas próprias de graça no Futuur.  Melhores previsões usando a sabedoria da multidão.',
      description_no_outcome:
        'Veja as ultimas previsões de ${this.username} na Futuur.  Aposte no Futuro de tudo, de esporte á política, e muito mais.',
    },
    open_positions: 'Posições abertas',
    life_time_profit_loss: 'Lucro/prejuízo vitalício',
    market_traded: 'Mercado negociado',
    edit_profile: 'Editar perfil',
  },
  email_confirmation_required: {
    resend_email: 'Enviar E-mail de verificação',
    change_email: 'Alterar E-mail',
    send_again: 'Enviar novamente',
    you_need_verify_email: 'Por favor, verifique seu e-mail para fazer previsões',
    confirmation_required_text: `Precisamos verificar seu e-mail por questões de segurança. Também utilizaremos este e-mail para enviar atualizações sobre suas previsões. Você pode ajustar suas notificações em suas preferências.`,
    make_sure_to_check: 'Verifique também sua caixa de SPAM.',
    sender_is: 'O remetente é info@futuur.com',
    email_verified: 'Email Verificado!',
    email_verified_from_another_account: 'Email verified from another account',
    email_verified_from_another_account_msg:
      'Por favor entre com a conta do email verificado para usar todas as functionalidades do Futuur.',
    invalid_token: 'URL de ativação inválida',
    time_to_predict_future: `Agora é hora de começar a prever o futuro!`,
    start_forecasting: 'Iniciar Previsões',
    back_to_login: 'Voltar ao Login',
    modal: {
      title: 'Verifique a sua caixa de entrada de e-mails',
      description:
        'Verifique seu e-mail para configurar sua conta e começar a apostar. Verifique também sua caixa de spam',
      button: 'Reenviar e-mail',
      sent_title: 'E-mail enviado!',
      sent_description:
        'Verifique sua caixa de entrada, se você não recebeu, por favor, entre em contato com nossa equipe de suporte',
    },
  },
  notifications: {
    market_is: 'Mercado está',
    cancelled: 'Cancelado',
    live: 'Ao vivo',
    you: 'Você ',
    the_bot_on: 'a aposta em ',
    for: 'para',
    commented: 'comentou',
    lost: 'perdeu',
    won: 'ganhou',
  },
  email_sent: {
    you_got_mail: "You've got mail",
    please_check_your_inbox: 'Please check your inbox and verify your account in order to place bets.',
  },
  leaderboard: {
    meta_tags: {
      title: 'Quem prevê melhor?  Melhores jogadores na Futuur.',
      description: 'Veja os melhores jogadores na Futuur.  Previsões mais precisas, através da inteligencia coletiva.',
    },
    this_week: 'Esta Semana',
    this_month: 'Este Mês',
    last_month: 'Últimos 30 dias',
    last_day: 'Últimas 24 horas',
    last_7days: 'Últimos 7 dias',
    all_time: 'Desde Sempre',
    all_categories: 'Todas as Categorias',
    user: 'Usuário',
    total_wagers: 'Previsões',
    earnings: 'Ganhos',
    net_worth: 'Ganhos Totais',
    leaderboard: 'Ranking',
    description: 'Nosso ranking considera apenas lucros e perdas',
    tooltip:
      'Nosso ranking é baseado no lucro realizado de mercados resolvidos e posições vendidas somado ao valor atual ' +
      'das apostas ativas.',
    in: 'em',
    all: 'Todos os Mercados',
    hot_market: 'Mercados do Momento',
  },
  user_ranking: {
    forecater_rank: 'Ranking do previsor',
    life_time_profit: 'Lucro',
    go_to_profile: 'Ir para o meu Perfil',
  },
  contracts: {
    terms_title: 'Termos de Uso',
    privacy_title: 'Política de Privacidade',
    contest_rules_title: 'Regras das Competições',
  },
  feedback: {
    header: 'Entre em contato conosco',
    subheader: 'Aguardamos seu contato!',
    footer: 'Se quiser entrar em contato conosco diretamente por e-mail, envie-o para',
    form: {
      inputs: {
        topic: {
          label: 'Tópico',
          placeholder: 'Selecione um tópico',
        },
        message: {
          label: 'Mensagem',
          placeholder: 'Digite aqui ...',
        },
        market_title: {
          label: 'Título do mercado',
          placeholder: 'Digite aqui ...',
        },
        attachment: {
          label: 'Adicionar captura de tela',
          drop_your_attachment_here: 'Coloque seu anexo aqui',
          accepted_file_types: 'Tipos de arquivo aceitos: jpg, jpeg e png',
          validation: {
            invalid_file_type_toast_title: 'O tipo (${this.type}) de arquivo (${this.name}) é inválido',
            invalid_file_type_toast_body: 'Faça upload de arquivos do tipo imagem (.png,.jpg,.jpeg)',
            invalid_file_size_toast_title: 'O tamanho do arquivo (${this.name}) (${this.size}) é muito grande',
            invalid_file_size_toast_body: 'Faça upload de arquivos menores ou iguais a 2,5 Mo',
            max_files_number_exceeded_toast_title: 'O número máximo de arquivos permitidos foi excedido',
            max_files_number_exceeded_toast_body: 'Faça upload de até 3 arquivos no máximo',
          },
        },
      },
      topics: {
        general_feedback: 'Comentários gerais',
        bug_report: 'Relatório de bug',
        market_suggestion: 'Sugerir um mercado',
        message_us: 'Envie-nos uma mensagem',
      },
      submit: 'Enviar',
    },
  },
  app_rate: {
    title: 'Mostre que gostou de nós!',
    text: 'Gostando do Futuur? Pedimos gentilmente que tire um momento para nos avaliar com 5 estrelas na App Store! ',
    confirm: 'sim!',
    cancel: 'agora não',
  },
  bet_entry: {
    lost: 'Perdeu',
    won: 'Ganhou',
    bet: 'Aposta',
    sale: 'Venda',
    disabled: 'Desativado',
    you_earned: 'Você ganhou',
    profit: 'com lucro de',
    you_lost: 'Você perdeu',
    shares: 'ações a um preço médio de',
    total: 'por ação, totalizando',
    you_paid: 'Você pagou',
    comission: 'como comissão',
    resolution: 'A resposta certa foi:',
    now_worth: 'Valor atual:',
    earned: 'Faturou',
    paid: 'e pagou',
    tooltip_text:
      'O Futuur cobra uma comissão de 10% sobre lucros. Para saber mais sobre como cobramos, consulte nossas FAQs.',
    tooltip_label1: 'Pre-commission profit',
    tooltip_label2: 'Commission rate',
    tooltip_label3: 'Commission',
  },
  bet_info: {
    warning_line1: 'O Futuur cobra uma comissão de 10% sobre seu lucro.',
    warning_line2: 'Para mais informações, consulte a seção como cobramos.',
    otherBets: 'Suas outras apostas neste mercado',
    aggregate: 'Resultado acumulado',
    past: 'Apostas passadas',
    last: 'estado da aposta',
    open: 'estado da aposta',
  },
  bet_history: {
    all: 'Todas as previsões',
    past: 'Previsões passadas',
    active: 'Previsões ativas',
    view_all: 'Ver todos',
    following: 'Apenas quem sigo',
    note: 'Atenção: ',
    tooltip_text: `por questões de segurança você pode apenas visualizar as informações de Ooms sobre outros usuários. Para realizar apostas em Ooms, por favor altere sua moeda atual.`,
    dismiss: 'Esconder',
    all_types: 'Todos',
    play_money: 'Moeda de Jogo',
    real_money: 'Dinheiro Real',
    only_followers: 'Apenas pessoas que sigo',
    my_bets: 'Minhas apostas',
    no_bet_found: 'Nenhuma aposta encontrada',
    be_the_first: 'Seja o primeiro apostador nesse mercado',
  },
  chart: {
    timeframes: {
      day: '1D',
      week: '1S',
      month: '1M',
      year: '1A',
      all_time: 'Todos',
    },
  },
  wallet: {
    balance: 'Saldo',
    add_funds: 'Depositar',
    withdraw: 'Saque',
    transactions: 'Transações',
    date_format: `d 'de' MMMM, yyyy`,
    all_time: 'Todos os tempos',
    ooms_balance: 'Saldo em Ooms',
    wallet_name: 'Carteira de ${this.currency}',
    pending_withdraw:
      `Você tem um saque pendente de ` + '${this.amount}' + `. A aprovação geralmente ocorre dentro de 24 horas.`,
    all: 'Todos',
    incomes: 'Créditos',
    outcomes: 'Débitos',
    export: 'Exportar',
    account_activity: 'Atividade da Conta',
    action_wager_purchase: 'Aposta',
    current_balance: 'Saldo atual',
    wallet: 'Carteira',
    action_wager_sale: 'Venda',
    action_fee: 'Comissão',
    action_wager_win: 'Acertou',
    action_wager_lose: 'Perdeu',
    action_deposit: 'Depósito',
    action_withdraw: 'Saque',
    action_initial_balance: 'Inicial',
    action_question_cancelled: 'Cancelado',
    action_question_reversion: 'Revertido',
    action_transaction_reversion: 'Revertido',
    action_withdrawal_request: 'Pedido de Saque',
    action_manual_transaction: 'Manual',
    action_question_settlement: 'Reajustado',
    action_pending_withdraw: 'Retirada pendente',
    action_cancel_withdraw: 'Cancelar Saque',
    action_other: 'Outro',
    no_result: 'Não encontramos nenhum resultado',
    from: 'De',
    to: 'Para',
    custom_period: 'Período personalizado',
  },
  cash_in: {
    kyc: 'Verificação de Conta',
    kyc_title: 'Verificação de Conta',
    add_bitcoins: 'Adicionar Criptomoedas à sua conta do Futuur',
    currencyList_subtitle: 'Selecione a que moeda criptográfica você gostaria de adicionar fundos.',
    add_currency: 'Transfira ${this.currency} para sua Carteira do Futuur',
    add_funds: 'Adicionar dinheiro',
    wallet_address: 'Seu endereço de ${this.currency} no Futuur',
    currency_list: 'Selecionar moeda',
    buy_btc: 'Comprar Criptomoedas',
    buy_crypto: 'Comprar Criptomoedas',
    copy_link: 'Copiar Endereço',
    current_balance: 'Saldo atual:',
    deposit_being_processed: 'Seu depósito está sendo processado',
    go_to_home: 'Ir para os Mercados',
    go_to_payment_gateway: 'Ir para o Skrill',
    is_being_processed: 'está sendo processado',
    proceed_to_payment: 'Ir para o método de pagamento',
    question_deposit_amount: 'Quanto você gostaria de depositar?',
    third_party_payment: 'Método de pagamento de terceiros',
    my_transactions: 'Ver todas as transações',
    we_use: 'Nós utilizamos o serviço ',
    see_withdraw_fees: 'Ver taxas para a retirada',
    see_withdraw_fees_here: 'aqui',
    your_deposit_of: 'Seu depósito de',
    we_use_complement:
      'para processar pagamentos de forma rápida e segura. Você será redirecionado de volta ao Futuur assim que seu pagamento for concluído. Até breve!',
    send_money: 'Envie ${this.currency} para este endereço para adicionar saldo à sua conta',
    minimum_deposit: 'O depósito mínimo é de 1.0',
    view_all_transactions: 'Minhas Transações',
    tooltip_title: 'Taxas para ${this.currencyLabel}',
    tooltip_fee_1: 'Uma taxa de',
    tooltip_fee_2: 'é cobrada pelos nossos intermediários de pagamento para cada retirada.',
    tooltip_withdrawal: 'Retirada mínima é de',
    missing_data: {
      title: 'Por favor, forneça algumas informações adicionais',
      description:
        'Para cumprir os regulamentos, precisamos coletar informações básicas que não serão compartilhadas ou vendidas, a menos que seja exigido por lei.',
    },
    network: 'Rede Blockchain',
    network_placeholder: 'Por favor, selecione uma rede',

    make_sure_network:
      'Certifique-se de estar usando a rede certa para fazer seu saque. As transferências usando a rede errada podem ser perdidas.',
    remaining_wagerings: 'Requisito de aposta restante: ',
    bonus_disclaimer:
      'Os depósitos podem ser sacados a qualquer momento. Os bônus exigem o cumprimento dos requisitos de apostas.',
    notification: {
      title: 'Endereço copiado!',
      body: 'O endereço da sua carteira Fuutur foi copiado.',
    },
    deposit: 'Depósito',
    deposit_crypto: 'Depósito de Criptomoeda',
    send_address_to_deposit: 'Envie para este endereço para depositar em sua conta',
    select_a_currency: 'Selecione uma moeda',
    select_a_cryptocurrency: 'Selecione uma criptomoeda',
    cryptocurrency: 'Criptomoeda',
    amount: 'Quantidade',
    select_realy_money: {
      fiat: {
        title: 'USD, EUR, BRL e outros',
        description: 'Cartão de crédito, transferência bancária ou outros métodos de pagamento',
      },
      crypto: {
        title: 'Depósito de Criptomoeda',
        description: 'Escolha a criptomoeda de sua preferência',
      },
      more: 'mais',
    },
    astropay_explained: 'O pagamento será processado pela Astropay, nosso parceiro de pagamento',
    and_other: 'e outros',
    deposit_status: {
      APPROVED: {
        title: 'Depósito realizado com sucesso!',
        description: 'Os fundos já estão disponíveis na sua carteira.',
        view_transactions: 'Ver minhas transações',
        ok: 'Ok, entendi',
      },
      SUCCESS: {
        title: 'Depósito realizado com sucesso!',
        description: ' Seu depósito de <b>${this.amount} ${this.currency}</b> já está disponível na sua',
        view_transactions: 'Ver minhas transações',
        ok: 'Ok, entendi',
      },
      PENDING: {
        title: 'Depósito em andamento',
        description: 'Seu depósito está sendo processado, você receberá um email assim que ele for confirmado.',
        ok: 'Ok, entendi',
      },
      CANCELLED: {
        title: 'Depósito cancelado!',
        description: 'Seu depósito foi cancelado.',
        ok: 'Ok, entendi',
      },
      ERROR: {
        title: 'País proibido',
        description:
          'Infelizmente, seu país não é suportado para depósitos em dinheiro real ou apostas. No entanto, você pode continuar a apostar com nossa moeda de jogo ‘OOMs’. Se o seu país de residência mudou, você pode atualizá-lo <a class="link" href="/p/personal-info-update" >aqui</a>.',
        ok: 'Ok, entendi',
      },
    },
    pix_success_paragraph: 'Acesse seu banco, opte pelo pagamento Pix e conclua sua transação.',
    crypto_success_paragraph: 'Use a rede correta para evitar a perda de transferências. ${this.network}',
    edit_info: 'Editar informações',
    done: 'Concluído',
    you_will_be_redirected_title: 'Você será redirecionado para',
    you_will_be_redirected_paragraph: 'Sua transação será processada por nosso parceiro de pagamento confiável',
    you_have_been_redirected_title: 'Você está sendo redirecionado para',
    you_have_been_redirected_paragraph: 'Sua transação será processada por nosso parceiro de pagamento confiável',
    withdraw_status: {
      pending: {
        title: 'Você tem um saque pendente',
        description:
          'Você já tem um saque pendente de <b>${this.amount}</b>. Por favor, confirme este saque no e-mail que enviamos para você.',
        cta: 'Reenviar E-mail',
      },
      post_submition: {
        title: 'Enviamos um email de confirmação',
        description:
          'Clique no link de confirmação do email que enviamos para você dentro de 48 horas para completar seu saque.',
        cta: 'Ok, entendi',
      },

      cancel_withdraw: {
        cta: 'cancelar saque',
        toast_message_title: 'Saque cancelado',
      },
      resend_sent: {
        toast_message_title: 'Email enviado',
        toast_message_description: 'Por favor, verifique sua caixa de entrada e confirme seu saque',
      },
    },
    other_deposit_methods_title: 'Outras opções',
    other_deposit_methods_description:
      'Cartão de crédito, transferência bancária, PicPay ou outros métodos de pagamento',
    direct_deposit_description: 'Seu depósito será processado em minutos',
    pix_only_for_brazil: 'Essa opção está disponível apenas para residentes no Brasil',
    pix_only_for_mexico: 'Essa opção está disponível apenas para residentes mexicanos',
    pix_key_notification: {
      title: 'Chave de pix copiada',
      description: 'A chave de pix foi copiada, você pode usá-la para fazer um depósito',
    },
    pix_qr_title: 'Depósito via Pix',
    pix_qr_description:
      'Acesse seu banco, opte pelo pagamento via Pix e conclua sua transação. Código válido por 30 minutos',

    SPEI_key_notification: {
      title: 'Chave SPEI Copiada',
      description: 'A chave SPEI foi copiada, você pode usá-la para fazer um depósito',
    },
    SPEI_qr_title: 'Depósito via SPEI',
    SPEI_qr_description: 'Por favor, complete a transferência para a seguinte CLABE',
    SPEI_copy: 'Copie o código abaixo para completar sua transferência',
    fiat_on_hold: 'Depósitos em dinheiro real estão em manutenção.',
  },
  how_to_buy_bitcoins: {
    buy_btc: 'Comprar Cripto',
    intro_text:
      'Se você precisar comprar criptografia, você pode fazer isso em qualquer uma das seguintes trocas. Você pode então depositar no Futuur fazendo uma transferência da bolsa para o endereço de sua carteira Futuur.',
    info_pix: 'PIX para transferências instantâneas',
    info_bank_transfer_deposit: 'Transferências Bancárias e Depósitos',
    info_direct_purchase: 'Livro de Ofertas ou Compra Direta',
    info_purchase_methods_1: 'Aceita PagSeguro, Paypal e Mercado Pago',
    info_find_exchange_p2p: 'Encontre valores no mercado P2P',
    info_pay_credit_card: 'Pague com Cartão de Crédito',
    info_directly_futuur_wallet: 'Diretamente para sua carteira do Futuur',
    info_check_country: 'Verifique a disponibilidade em seu país e Estado',
    info_buy_sell_bitcoins: 'Venda e compra de Criptomoedas',
    info_bank_account_debit_credit: 'Conta bancária, cartão de crédito e débito',
    go_to: 'Ir para ${this.provider}',
  },
  kyc_warning: {
    text: 'Para ajudar a manter o Futuur um lugar seguro, por favor envie sua declaração de identidade. Costuma levar menos que 2 minutos.',
    small_title: 'Oops! Você está trancado por fazer novas apostas',
    verify_your_identity: 'Verifique sua identidade',
    start_id_verification: 'Verificar Identidade',
    unlock_deposit_warning:
      'Habilite um limite para depósito mais alto e melhore a segurança geral de sua conta. Comece seu processo de KYC agora.',
  },
  kyc_form: {
    first_name: 'Primeiro nome',
    last_name: 'Sobrenome',
    country: 'Residente em',
    country_placeholder: 'País de residência',
    date: 'Data de nascimento',
    submit: 'Enviar',
    error_first_name: 'Mínimo 5 caracteres necessários',
    error_last_name: 'Mínimo 5 caracteres necessários',
    address: 'Endereço',
    address_placeholder: 'Adicione seu endereço completo',
    success_message: 'Dados pessoais atualizados',
    error_address: 'Mínimo 5 caracteres necessários',
    first_name_placeholder: 'Adicione seu primeiro nome',
    last_name_placeholder: 'Adicione seu sobrenome',
  },
  kyc: {
    status: {
      no_need: 'Não há necessidade de verificação',
      initialized: 'Em andamento',
      refused: 'Recusado',
      approved: 'Aprovado',
    },
    action: 'Início',
    paragraph: {
      refused: 'Sua verificação de identidade foi recusada. Por favor, tente novamente.',
      requested: 'Aumente seus limites de depósito completando nosso processo KYC.',
    },
  },
  withdraw: {
    currency_list: 'Selecionar moeda',
    add_skrill_account: 'Adicionar uma conta do Skrill',
    available_withdraw_methods: 'Métodos de retirada disponíveis',
    wallet_address: 'Carteira de ${this.currency}',
    choose_funds_destination: 'Escolha a conta de destino',
    continue: 'Continuar',
    current_balance: 'Saldo atual:',
    wallet_address_placeholder: 'Endereço de sua carteira de ${this.currency}',
    how_much_send_via: 'Quanto você deseja enviar via ',
    how_much: 'Quantos ${this.currency} você deseja retirar?',
    is_being_processed: 'está sendo processado',
    minimum_withdraw_crypto: 'A retirada mínima é de ${this.value}',
    tax_withdraw_crypto: 'Uma taxa de ${this.tax} é cobrada pelo nosso provedor de pagamentos.',
    money_on_the_way: 'Seu dinheiro está a caminho!',
    payment_being_processed: 'Sua retirada está sendo processada, informaremos assim que estiver tudo certo.',
    send: 'Enviar',
    invalid_address: 'Endereço inválido',
    invalid_address_warning: 'Esse não é um endereço ${this.currencyName} válido, por favor verifique.',
    view_all_transactions: 'Ver todas as transações',
    withdraw_done: 'Seu dinheiro está a caminho!',
    withdraw_funds: 'Retirada de dinheiro',
    withdraw_value: 'Quantia a ser retirada',
    withdraw: 'Retirada',
    your_amount_of: 'Seu montante de ',
    user_no_account_for_currency: 'Você precisa adicionar saldo a esta moeda primeiramente',
    values_in_mbtc: 'Digite o valor a ser sacado em miliBitcoin (1mBTC = 0,001 BTC)',
    warning_usdc:
      'Utilize somente a rede de ${this.blockchain} para esta transaçao, caso contrário os valores podem ser perdidos.',
    currently_have: 'Você atualmente tem ',
    min_value: 'O valor mínimo de saquel é ${this.min_value}',
    max_value: 'O valor maximo de saquel é ${this.maxF_value}',
    blockchain_network: 'Rede Blockchain',
    ensure_network:
      'Por favor, certifique-se de que está usando a Rede correta. Transferências feitas para a rede errada podem ser perdidas.',
    withdraw_funds_to_astropay: 'Sacar fundos para sua Conta Astropay',
    withdraw_funds_to_pix: 'Sacar fundos para sua Conta Bancária via PIX',
    pix_key: 'Digite sua chave PIX',
    payment_will_processed_with_astropay: 'O pagamento será processado pela Astropay, nosso parceiro de pagamento',
    amount: 'Quantia',
    edit_information: 'Editar informações',
    cpf: 'Qual é o seu CPF?',
    random_key: 'Chave Aleatória',
    cell_phone: 'Celular',
    resume: 'Resumo',
    pix_key_type: 'Pix Tipo de chave',
    review_transfer: 'Revise sua transferência:',
    withdrawal_under_min_amount: 'Saque abaixo do valor mínimo',
    min_ten: 'Retirada mínima: US$ 10 ou R$ 60. ',
    enter_pix_key: 'Digite sua chave de pixel',
  },
  withdraw_email_received: {
    title: 'E-mail de confirmação enviado, verifique sua caixa de entrada',
    text: 'Por favor, clique no link do e-mail de confirmação para verificar sua solicitação e iniciar o processo de retirada.',
    close: 'Fechar',
  },
  withdraw_validation: {
    no_longer_valid: 'Seu saque de ${this.amount} não é mais válido',
    initiated: 'Sua retirada foi iniciada.',
    receive_notification: 'Você receberá uma notificação e e-mail quando a transação estiver completa.',
    request_another: 'Por favor, faça outro pedido se você deseja sacar fundos de sua conta',
    error_p1:
      'Um erro ocorreu ao tentar validar sua requisição. Seu token pode ter expirado ou a transação já ter sido validada.',
    error_p2: 'Se você precisar de um suporte adicional, por favor nos contate.',
    go_to_markets: 'Ir para os mercados',
    view_transactions: 'Ver minhas transações',
    request_verified: 'Retirada verificada!',
    request_canceled: 'Esta solicitação foi cancelada!',
    title_error: 'Ops... há algo errado',
    verifying_request: 'Verificando requisição',
  },
  currency_selector: {
    title: 'Moeda selecionada',
    intro: 'Selecione os mercados que você deseja explorar e selecione a moeda para realizar previsões',
    about: 'Sobre',
    info: 'Mudar sua moeda atual irá alterar a moeda que você utiliza para realizar apostas no Futuur. Escolha Ooms para apostar utilizando moeda de jogo, ou qualquer criptomoeda para utilizar uma moeda real.',
    dismiss: 'Fechar',
    add_funds: 'Adicionar saldo',
    add_more: 'Adicionar mais',
    deposit: 'Depósito',
    withdraw: 'Saque',
    confirm: 'Confirmar moeda selecionada',
    continue: 'Continuar',
    cancel: 'Cancelar',
    play_money_markets: 'Mercados de Moedas de Jogo',
    real_money_markets: 'Mercados de Moedas de Verdade',
    play_money_title: 'Ooms',
    real_money_title: 'Dinheiro de Verdade',
    play_money: 'Dinheiro de Jogo',
    view_only: 'Somente Visualização',
    real_money: 'Dinheiro de Verdade',
    tooltip_title: 'Como funciona?',
    tooltip_description:
      'O seletor de Moeda determina quais mercados você vê (dinheiro de verdade ou moeda de jogo), e qual moeda você usa para apostar.',
    check_wagering_forbidden_country:
      'Enquanto não fornecemos apostas com dinheiro de verdade em sua jurisdição, você ainda poderá acessar todos os mercados para visualização.',
    check_web_version:
      'Para verificar a eligibilidade a apostas com dinheiro de verdade, por favor acesse nossa versão web em <a href="https://futuur.com" target="_blank" >https://futuur.com</a>',
    please_register: 'Para realizar apostas e receber 1000 Ooms, por favor, registre-se',
    register: 'Registrar',
    sign_in: 'Login',
    add_or_remove_currency: 'Adicionar ou remover moeda',
    check_availability: 'verificar disponibilidade',
    view_web_version: 'Ver versão web',
    intro_app:
      'Você somente pode ver mercados de Ooms por conta de sua jurisdição. Para verificar a disponibilidade de mercados em moeda real, por favor <a href="https://futuur.com" target="_blank" >Verifique a versão web</a>.',
    no_funds: 'Por favor, deposite fundos para apostar em mercados de dinheiro real',
    fromForbiddenCountry:
      'Você está acessando o Futuur de uma jurisdição restrita e não pode apostar em mercados de dinheiro real.  No entanto, pode sim visualizar os mercados e previsões de dinheiro real, e também pode apostar à vontade em nossos mercados de dinheiro fictício!',
    confirm_button: 'Confirmar mudança',
    change_for: 'mudança para ',
    currency_changed: 'Moeda selecionada alterada',
    currency_changed_description: 'Você agora está vendo mercados em ${this.currency_mode}.',
    offline_description:
      'Você está visualizando previsões geradas pelo público dos mercados de ${this.currency_mode}. Para visualizar as previsões dos mercados de ${this.other_mode}, use o botão de alternância abaixo.',
    add_another_currency: 'Adicionar outra moeda',
    click_to_select: 'Clique para selecionar a moeda padrão para apostar',
    sigin_in: 'Para fazer apostas, faça o login:',
    no_funds_yet: 'Sem saldo',
  },
  onboarding_app: {
    button: {
      b1: 'Vamos lá!',
      b2: 'Oba, dinheiro!',
      b3: 'Entendido',
      b4: 'OK!',
      b5: 'Certo, vamos começar!',
    },
    slide_1: {
      l1: 'Olá',
      l2: 'Bem-vindo(a) ao ',
      l3: 'Sua carreira prevendo o futuro',
      l4: 'começa agora!',
    },
    slide_2: {
      l1: 'No Futuur, você prevê o resultado de eventos futuros usando nossa moeda virtual, o Oom (ø).',
      l2: 'Você começará com 10.000ø.',
      l3: 'Invista-os com sabedoria!',
    },
    slide_3: {
      l1: 'O preço de cada alternativa representa a probabilidade desse resultado acontecer, de acordo com nosso mercado de previsões.',
    },
    slide_4: {
      l1: 'Se o resultado que você previu acontecer, você receberá 1ø por cada ação comprada.',
      l2: 'Dica de expert: ',
      l3: 'se você achar que a probabilidade de um resultado é mais alta do que o preço atual, essa é uma aposta inteligente!',
    },
    slide_5: {
      l1: 'Os preços flutuam conforme os membros da comunidade fazem suas previsões.',
      l2: 'Você pode vender sua aposta a ',
      l3: 'qualquer momento',
      l4: ' — não precisa esperar que a pergunta seja resolvida.',
    },
  },
  onboarding_tour: {
    button_1: 'Vamos Lá!',
    button_2: 'Ok!',
    skip: 'Pular Intro',
    continue: 'Continuar',
    lets_go: 'Vamos Lá!',
    ready_to_predict: 'Vamos Prever !',

    intro_real_money: {
      title: 'Olá!',
      p1: 'Seja bem-vindo ao Futuur. Aqui você pode fazer dinheiro com o que sabe, ou usar só por diversão',
      p2: 'Junte-se a nós para um rápido resumo',
    },

    intro_play_money: {
      title: 'Olá!',
      p1: 'Seja bem-vindo ao Futuur. Aqui você pode realizar apostas em praticamente qualquer coisa e ajudar a criar melhores predições para todos.',
      p2: 'Junte-se a nós para um rápido resumo!',
    },

    ooms_slide: {
      title: 'Você ganhou Ooms!',
      p1: 'Todo mundo começa com 10,000ø em Ooms, nossa moeda de jogo',
      p2: 'Ooms não estão à venda, então use-os com sabedoria & suba nos rankings!',
    },

    make_bet: {
      title: 'Encontre mercados & faça previsões',
      p1: 'Para cada resultado possível você verá uma porcentagem indicada',
      p2: 'A porcentagem representa a probabilidade atual do resultado, de acordo com a nossa comunidade.',
    },

    buy_and_sell: {
      title: 'Compre e Venda',
      p1: 'Conforme as previsões são feitas os preços do resultado sobem ou descem',
      p2: 'Você pode vender suas posições a qualquer momento para realizar lucros ou cortar perdas',
    },

    add_funds: {
      title: 'Adicone dinheiro de verdade!',
      p1: 'Faça depósitos usando as suas criptomoedas favoritas',
      p2: 'Não há depósito mínimo e você pode fazer saques a qualquer momento',
    },
  },
  pwa_installation: {
    title: 'Adicionar Futuur à tela inicial?',
    subtitle: 'Para acesso rápido e fácil',
    actionButton: 'Adicionar à tela inicial',
  },
  ask_notification_permission: {
    header: 'Notificações ao navegador',
    title: 'Permitir que a Futuur envie notificações ao navegador',
    subtitle: 'Fique atualizado sobre os últimos mercados Futuur!',
    actionButton: 'Permitir notificação do navegador',
  },
  error: {
    text: 'Isso não era esperado. <br /> Para voltar clique em "Ir para página inicial"',
    button: 'Ir para página inicial',
  },
  user_preview: {
    joined: 'Aderiu',
    view_profile: 'Ver perfil',
    market_traded: 'Mercado negociado',
    win_rate: 'Taxa de vitória',
    life_time_profit_and_loss: 'Lucro vitalício/perda',
    open_positions: 'Vagas em aberto',
    follow: 'Seguir',
    unfollow: 'Deixar de seguir',
  },

  account_settings: {
    delete_account: {
      delete_account_button_text: 'Excluir conta',
      skip_button_text: 'Pular',
      submit_button_text: 'Enviar',
      errors: {
        withdraw_before_delete: {
          title: 'Saque antes de excluir sua conta',
          body: 'Você não pode excluir sua conta antes de sacar todo o seu saldo.',
        },
      },
      reasons: {
        lost_too_many_times: 'Perdi muitas vezes',
        markets_are_not_interesting: 'Os mercados não são interessantes',
        ran_out_of_play_money: 'Fiquei sem dinheiro de jogo',
        lack_of_volume_in_markets: 'Baixo volume nos mercados',
        lack_of_engagement_between_players: 'Baixo de engajamento entre os jogadores',
        other: 'Outros',
      },
      reason_selector_title: 'Pode nos contar o motivo?',
      reason_selector_info_message:
        'Isso nos ajudará a melhorar nosso produto. Não se preocupe, você não será contatado.',
      add_your_reason: 'Adicione seu motivo',
      confirmation_title: 'Deseja excluir sua conta?',
      confirmation_info_message:
        'Lembrete importante: Excluir sua conta apagará permanentemente seu histórico de previsões e não poderá ser recuperado.',
      cancel_button_text: 'Cancelar',
      confirm_deletion_button_text: 'Confirmar e excluir',
      account_deleted_successfully: 'Conta excluída com sucesso',
    },
  },
  hot_topic_banner: {
    markets: 'mercados',
  },
  common: {
    markets: 'Mercados',
    score: 'Pontuação',
  },
}
