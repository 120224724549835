import React, {useEffect, useState} from 'react'
import classNames from 'classnames'

import {useScrollDirection} from 'common/hooks'
import {NavLink, useLocation} from 'react-router-dom'
import {accountUrl} from 'profile/urls'
import {feedUrl, leaderboardUrl, walletUrl} from 'urls'
import {GlobeIcon, MagnifyIcon, PortfolioIcon, RankingIcon, WalletIcon} from 'common/components/icons'
import {selectPageLayout, setGoToWebVersionDisplayed} from 'store/app'
import {useDispatch, useSelector} from 'react-redux'
import Device from 'common/util/Device'
import GoToWebVersion from 'common/components/go-to-web-version'
import './Tabbar.scss'
import {i18n} from 'common/locale/i18n'
import {Sheet} from "react-modal-sheet";
import ActionSheetLayout from "layouts/components/action-sheet-layout";
import SearchComponent from "explore/components/search-component/SearchComponent";
import useIsMobileSize from "common/hooks/useIsMobileSize";

function Tabbar({ className, me }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const layout = useSelector(selectPageLayout)
  const scrollDirection = useScrollDirection(0)
  const [isVisible, setVisible] = useState(true)
  const [showSearchPanel, setShowSearchPanel] = useState(false)
  const isMobileSize = useIsMobileSize();

  useEffect(() => {
    setVisible(scrollDirection !== 'down')
  }, [scrollDirection])

  useEffect(() => {
    if (layout.showTabBar === false) {
      setVisible(false)
    }
  }, [layout])

  const scrollToTop = e => {
    if (location.pathname === feedUrl()) {
      e.preventDefault()
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  const isLogged = !!(me && me.id)

  return (
    <React.Fragment>
      {Device.isCordova && layout.goToWebVersionDisplayed && (
        <div className={classNames('tabbar__web-version', { 'tabbar__web-version--hide': !isVisible })}>
          <GoToWebVersion onClose={() => dispatch(setGoToWebVersionDisplayed(false))} />
        </div>
      )}

      <div
        className={classNames('tabbar', className, {
          'tabbar--hide': !isVisible,
        })}>
        <NavLink
          className="tabbar-button"
          activeClassName="tabbar-button--active"
          to={feedUrl()}
          exact
          onClick={scrollToTop}>
          <span className="tabbar-button__icon" alt="Feed">
            <GlobeIcon width="20px" />
          </span>
          {i18n('navbar.markets')}
        </NavLink>

        {isLogged && (
          <NavLink className="tabbar-button" activeClassName="tabbar-button--active" to={accountUrl()}>
            <span className="tabbar-button__icon" alt="Account">
              <PortfolioIcon width="20px" />
            </span>
            {i18n('navbar.portfolio')}
          </NavLink>
        )}

        <NavLink className="tabbar-button" activeClassName="tabbar-button--active" to="#" onClick={() => setShowSearchPanel(true)}>
          <span className="tabbar-button__icon" alt="Search">
            <MagnifyIcon width="20px" />
          </span>
          {i18n('navbar.search')}
        </NavLink>


        <NavLink className="tabbar-button" activeClassName="tabbar-button--active" to={leaderboardUrl()}>
          <span className="tabbar-button__icon" alt="Community">
            <RankingIcon height="18px" />
          </span>
          {i18n('navbar.leaderboard')}
        </NavLink>


        <NavLink className="tabbar-button" activeClassName="tabbar-button--active" to={walletUrl()}>
          <span className="tabbar-button__icon" alt="Community">
            <WalletIcon height="18px" />
          </span>
          {i18n('navbar.wallet')}
        </NavLink>
      </div>

      {
        isMobileSize && (
          <Sheet
            isOpen={showSearchPanel}
            onClose={() => setShowSearchPanel(false)}
            detent="content-height"
            disableScrollLocking>
            <Sheet.Container>
              <Sheet.Content className="bg-Neutral-1 rounded-t-2xl !min-h-[90vh]">
                <ActionSheetLayout
                  title={i18n('navbar.search')}
                  content={
                    <SearchComponent onMarketClick={() => setShowSearchPanel(false) }
                                     onForecasterClick={() => setShowSearchPanel(false) }
                                     onSuggestMarketClick={() => setShowSearchPanel(false) }
                                     neverHideSearchResultsWidget />
                  }
                  action={null}
                  buttonDisabled={true}
                  onButtonClick={null}
                  footer={null}
                  showCloseButton={true}
                  onClose={() => setShowSearchPanel(false)}
                  headerClassList="!px-6 !py-4 ml-0 mr-0 text-xl leading-[133%] font-bold"
                  containerClass="!pl-4 !pr-4 !pt-4 ml-0 mr-0 !min-h-[90vh]"
                  closeButtonClassName="!top-2.5"
                />
              </Sheet.Content>
            </Sheet.Container>
            <Sheet.Backdrop />
          </Sheet>
        )
      }

    </React.Fragment>
  )
}

Tabbar.height = 78 // $tabbar-height

export default Tabbar
