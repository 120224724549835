import React from 'react'
import './Chip.scss'
const Chip = ({ active = false, text, value, onClick = () => {}, className}) => {

  const _className = className || `chip ${active ? 'chip--active' : ''}`

  return (
    <div onClick={() => value && onClick(value)} className={_className}>
      {text}
    </div>
  )
}

export default Chip
