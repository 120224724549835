// look at SwipeableViews for the swipe event handler
// PredictionList and Notifications has the component (tabs)
// Animation from the Notifications is preferred

import React from 'react'
import classNames from 'classnames'
import './TabbedContent.scss'
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import Slider from 'common/ui/slider/Slider' 
import useIsMobileSize from 'common/hooks/useIsMobileSize'

const TabbedContent = ({ className, contents, defaultIndex = 0, header, ...props }) => {
  const tabsRef = useRef([])
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0)
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0)
  const [activeIndex, setActiveIndex] = useState(props.activeIndex || defaultIndex)
  const isMobileSize = useIsMobileSize();

  let index = defaultIndex

  const handleTabClick = (event, clickedIndex) => {
    const ai = props.activeIndex === undefined ? activeIndex : props.activeIndex

    const { onContentChange } = props

    if (clickedIndex !== ai) {
      setActiveIndex(clickedIndex)
      if (onContentChange) onContentChange(clickedIndex)
    }
  }

  useEffect(() => setActiveIndex(props.activeIndex), [props.activeIndex])
  useEffect(() => {
    const setTabPosition = () => {
      const currentTab = tabsRef.current[activeIndex] || document.getElementsByClassName('tab-item--active')[0]
      const offsetLeft = currentTab?.offsetLeft || 0
      setTabUnderlineLeft(offsetLeft + 8)
      setTabUnderlineWidth(currentTab?.clientWidth - 16)
      // setTabUnderlineWidth(currentTab?.clientWidth ?? 0)
    }

    setTabPosition()
    window.addEventListener('resize', setTabPosition)

    return () => window.removeEventListener('resize', setTabPosition)
  }, [activeIndex, tabsRef.current, contents])

  if (props.activeIndex !== undefined) {
    index = props.activeIndex
  } else {
    index = undefined
  }
  const content = contents[index]
  const sliderSettings = {
    slidesPerView: 'auto',
    centeredSlides: false,
    spaceBetween: 0,
    nested: true,
  }
  const tabbedLabels = () => {
    return contents.map((item, i) => {
      return (
        <button
          key={i}
          ref={el => (tabsRef.current[i] = el)}
          className={classNames('tab-item', {
            'tab-item--active': i === index,
          })}
          onClick={event => {
            handleTabClick(event, i)
          }}
          data-index={i}>
          {item.label}
        </button>
      )
    })
  }
  return (
    <div className={classNames(className, 'tabbed-container')}>
      <nav className="tabbed-container__tab-list ">
        {isMobileSize ? (
          <>
            <Slider settings={sliderSettings}>{tabbedLabels()}</Slider>
            <span className="tabbed-container__tab--underline" style={{ width: '100%' }}></span>
          </>
        ) : (
          <>
            {tabbedLabels()}
            <span className="tabbed-container__tab--underline" style={{ width: '100%' }}></span>
            <span
              className="tabbed-container__tab--underline--active"
              style={{
                left: isNaN(tabUnderlineLeft) ? 0 : tabUnderlineLeft,
                width: isNaN(tabUnderlineWidth) ? 0 : tabUnderlineWidth,
              }}></span>
          </>
        )}
      </nav>
      <section className="tabbed-container__content">
        {header && (
          <header className="container-md-wide">
            <div>{header}</div>
          </header>
        )}
        {content && content.render()}
      </section>
    </div>
  )
}

export default TabbedContent
