import React, {useEffect, useState} from 'react'
import UserPicture from "profile/components/user-picture";
import Button from "common/ui/button";
import {useDispatch, useSelector} from "react-redux";
import {i18n} from "common/locale/i18n";
import {fetchUserSummary, followUser, selectUserSummary, selectUserSummaryStatus, unfollowUser} from 'store/users'
import {toastService} from 'api/toast'
import EmailNotConfirmed from 'app/components/modal-provider/global-modal/email-not-confirmed'
import {useCurrentUser} from 'common/hooks'
import {useModal} from 'app/components/modal-provider/ModalProvider'
import constants from 'app/constants'
import { useNavigate } from "react-router-dom";
import {userUrl} from "urls";

const SearchForecastersResultItem = ({user, onForecasterClick = () => {}}) => {
  const me = useCurrentUser()
  const { showModal } = useModal()
  const dispatch = useDispatch()
  const currentUserSummary = useSelector(selectUserSummary(me))
  const currentUserSummaryStatus = useSelector(selectUserSummaryStatus(me))
  const following_user_ids = currentUserSummary && currentUserSummary.following_user_ids
  const [isFollowing, setFollowing] = useState(me && me.id && user && following_user_ids && following_user_ids.includes(user.id))
  const navigate = useNavigate()

  useEffect(() => {
    if (me && currentUserSummaryStatus === constants.IDLE) {
      dispatch(fetchUserSummary(me))
    }
  }, [me])

  const toggleFollow = (event) => {
    event.preventDefault()
    event.stopPropagation()

    if (isFollowing)
    {
      onUnfollow(user)
      setFollowing(false)
    }
    else
    {
      onFollow(user)
      setFollowing(true)
    }
  }

  const onFollow = user => {

    if (me && !me.email_confirmed)
    {
      showModal({
        title: ' ',
        showCloseButton: true,
        content: <EmailNotConfirmed />,
      })
      return
    }

    dispatch(followUser(me, user.id))

    toastService('userFollow', user)
  }

  const onUnfollow = user => {
    dispatch(unfollowUser(me, user.id))
    toastService('userUnfollow', user)
  }

  const handleUsernameOnClick = () => {
    onForecasterClick()
    navigate(userUrl(user))
  }

  return (
    <div key={user.username} className="flex flex-row gap-4 py-3 items-center cursor-pointer">
      <UserPicture user={user} width={48} height={48} enableNavigateToProfile={true} onUserClick={onForecasterClick} imageClassName="!rounded min-w-12"/>

      <div className="flex flex-row justify-content-between items-center w-full">
        <h3 onClick={handleUsernameOnClick} className="text-Neutral-6">{user.username}</h3>
        <div>
          {
            me &&
            me.id &&
            me.id !== user.id && (
              <div>
                <Button
                  onClick={toggleFollow}
                  type="text-only"
                  size="medium"
                  className="text-base leading-[100%] font-bold px-6 h-10"
                  variant="primary">
                  { isFollowing ? i18n('user_preview.unfollow') : i18n('user_preview.follow') }
                </Button>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default SearchForecastersResultItem
