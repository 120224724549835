import React, {useEffect, useRef, useState} from 'react'
import {SearchWidget} from "app/components/Search-widget";
import SearchBox from "explore/components/search-box/SearchBox";

const SearchComponent = ({
                            inputValue = "",
                            neverHideSearchResultsWidget = false,
                            onMarketClick = null,
                            onForecasterClick = null,
                            onSuggestMarketClick = null,
                         }) => {

  const [isResultsVisible, setIsResultsVisible] = useState(false)
  const [searchInput, setSearchInput] = useState(inputValue)
  const searchRef = useRef(null); // Reference to search container

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsResultsVisible(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onMarketClickDefaultBehavior = () => {
    if(!neverHideSearchResultsWidget)
    {
      setIsResultsVisible(false)
    }
  }

  const onForecasterClickDefaultBehavior = () => {
    if(!neverHideSearchResultsWidget)
    {
      setIsResultsVisible(false)
    }
  }

  const onSuggestMarketClickDefaultBehavior = () => {
    if(!neverHideSearchResultsWidget)
    {
      setIsResultsVisible(false)
    }
  }

  return (
    <div className="navbar__search" ref={searchRef}>
      <SearchBox
        initialValue={searchInput}
        placeholder="Search here..."
        id="search__bar-input"
        onChange={(value) => {
          setSearchInput(value)

          if(value && value.length >1)
          {
            setIsResultsVisible(true)
          }
          else
          {
            setIsResultsVisible(false)
          }

        }}
        onFocus={() => {
          if(searchInput && searchInput.length >1)
          {
            setIsResultsVisible(true)
          }
        }}
      />
      <SearchWidget text={searchInput} isOpen={isResultsVisible || neverHideSearchResultsWidget}
                    onMarketClick={onMarketClick ? onMarketClick : onMarketClickDefaultBehavior}
                    onForecasterClick={onForecasterClick ? onForecasterClick : onForecasterClickDefaultBehavior}
                    onSuggestMarketClick={onSuggestMarketClick ? onSuggestMarketClick : onSuggestMarketClickDefaultBehavior}
      />
    </div>
  )
}

export default SearchComponent
