import React from 'react';
import {InfiniteList} from 'common/components/infinite-list'
import constants from 'app/constants'
import {Link} from "react-router-dom";
import {keepQueryString, questionDetailUrl} from "urls";
import {StatsIcon} from "common/components/icons";
import {formatNumber} from "common/util/localizeNumber";
import {useActiveCurrency, useCurrencies} from "common/hooks";
import {useSelector} from "react-redux";
import {selectActiveLanguage} from "store/app";

const SearchMarketsResult = ({
                     markets,
                     pagination,
                     status,
                     onLoadMore = () => {},
                     onMarketClick = () => {},
                   }) => {

  const currencies = useCurrencies().currencies
  const activeCurrency = useActiveCurrency()
  const isRealMoney = activeCurrency && activeCurrency.id !== currencies.OOM.id
  const locale = useSelector(selectActiveLanguage())

  const volumeLabel = market => {
    if (!market || (isRealMoney && !market.volume_real_money) || (!isRealMoney && !market.volume_play_money))
      return null
    let label = isRealMoney
      ? '$' + formatNumber(market.volume_real_money, locale, 2, 2)
      : formatNumber(market.volume_play_money, locale, 0, 0)

    return (
      <span>
        {isRealMoney && '~'} {label}
      </span>
    )
  }

  const renderVolume = market => {
    return volumeLabel(market) ? (
      <div>
        <div className="flex flex row items-center gap-1">
          <StatsIcon fillcolor="var(--color-Neutral-6)" width="13px" className="hover:text-Neutral-6"/>
          <span className="text-Neutral-6 text-sm">
            {volumeLabel(market)}
            {currencies[activeCurrency.id].id === 'OOM' ? ' ø' : ''}
          </span>
        </div>
      </div>
    ) : (
      <></>
    )
  }

  const getOutcomePercentage = (outcome, canonical_currency) => {

    return (outcome.price[canonical_currency] * 100).toFixed()
  }

  return (
    <InfiniteList
      onLoadMore={onLoadMore}
      hideShowMoreButton={!pagination || !pagination.next}
      offset={pagination.offset}
      nextOffset={pagination.offset + 20}
      isLoading={status === constants.LOADING}>
      <div className="category-results flex flex-col h-[69vh] md:h-full !overflow-auto scrollbar-none">
        {
          markets.map((market, index) => (
            <Link
              to={keepQueryString(questionDetailUrl(market))}
              onClick={onMarketClick}
              key={'search' + (market ? market.id : '44')}>

              <div className={`flex flex-row items-center pb-6 gap-4 ${index > 0 ? 'pt-6' : 'pt-0'}`}>
                <img className="rounded h-12 w-12" src={market.outcomes[0].thumbnail_2x_webp} alt={market.title}/>

                <div className="flex flex-col gap-3 w-full">
                  <h3 className="text-Neutral-6 text-base font-bold leading-[133%]">
                    {market.title}
                  </h3>

                  <div className="flex flex-row items-center">
                    <div className="text-base text-[--color-brand-04] font-bold mr-2">
                      {getOutcomePercentage(market.outcomes[0], market.canonical_currency)} %
                    </div>

                    <h3 className="text-Neutral-4 text-sm font-medium mr-10">
                      {market.outcomes[0].title}
                    </h3>

                    {renderVolume(market)}
                  </div>
                </div>
              </div>

            </Link>
          ))
        }
      </div>
    </InfiniteList>
  );
};

export default SearchMarketsResult;
