import React, { useRef, useEffect } from 'react'
import { useOnScreen } from 'common/hooks'
import Button from 'common/ui/button'
import { i18n } from 'common/locale/i18n'

function InfiniteList({ loadMoreAction = 'auto', onLoadMore, children, hideShowMoreButton = false, isLoading = false, offset = 0, nextOffset = 0 }) {
  const ref = useRef()
  const onScreen = useOnScreen(ref, '0px')

  useEffect(() => {
    if (loadMoreAction === 'auto' && onScreen && onLoadMore) {
      onLoadMore({ currentOffset: offset, nextOffset:nextOffset })
    }
  }, [onScreen])

  return (
    <div className="infinite-list">
      {children}
      {loadMoreAction === 'button' && !hideShowMoreButton && (
        <Button size="full" type="text-only" onClick={onLoadMore} loading={isLoading}>
          {i18n('infinite_grid.load_more')}
        </Button>
      )}
      <div className="infinte-list__anchor" ref={ref} />
    </div>
  )
}

export { InfiniteList }
